import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/navigation'
import { useSearchParams } from 'next/navigation'

import { loggedIn, loggedOut, updatedUser } from '@graphql/session.graphql'
import { useSession } from '@App/hooks'

export default function SubscriptionsProvider({ children }) {
  const { refetch, session, agent, dispatch } = useSession()
  const { replace } = useRouter()
  const searchParams = useSearchParams()

  if (typeof window != 'undefined')
    useSubscription(loggedIn, {
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onData: ({ data }) => {
        if (!data.data?.loggedIn) return
        dispatch({
          type: 'SET_SESSION',
          payload: data.data.loggedIn,
        })
        const redirectedFrom = searchParams.get('redirect')
        if (redirectedFrom) {
          const params = {}
          searchParams.forEach((value, key) => {
            params[key] = value
          })
          const query = new URLSearchParams(params)
          replace(`${decodeURIComponent(redirectedFrom.toString())}?${query.toString()}`)
        } else {
          replace('/')
        }
      },
      skip: !agent,
    })

  if (typeof window != 'undefined')
    useSubscription(loggedOut, {
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      skip: !session?.user,
      onData: ({ data }) => {
        if (data.data?.loggedOut == null) return
        dispatch({
          type: 'SET_SESSION',
          payload: null,
        })
        replace('/', '/')
      },
    })

  if (typeof window != 'undefined')
    useSubscription(updatedUser, {
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      skip: !session?.user,
      onData: ({ data }) => {
        if (data.data?.updatedUser === null) return
        refetch()
      },
    })

  return children
}
